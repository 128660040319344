import React from "react";
import { graphql, Link } from 'gatsby';
import Layout from "../components/layout";
import FluidImage from "../components/fluidImage";
import WPLink from "../components/wpLink";
import Seo from 'gatsby-plugin-wpgraphql-seo';
import styles from "./page.module.scss";

export default (props) => {

	const {
		data: {
			pageInfo,
			pageInfo: {
				title,
				ACFPageInfo: {
					pageInfo: {
						left: {
							blurb,
							requestAppointmentLink,
							insurancesHeading,
						},
						right: {
							introContent: {
								blurb: introBlurb,
								heading: introHeading,
								link: introLink
							},
							pageContent: content,
						}
					}
				}
			},
			options: {
				headerSection: {
					ACFHeaderOptions: {
						mainNavigationSettings: {
							headerLogo
						} 
					}
				}

			},
			insurance: {
				nodes: insurances
			},
			rightArrowYellow,
			introBGMobile,
			introBGTablet,
			introBGDesktop,
			introBGXL,
			appointmentPage,
		}
	} = props;

	return (
		<Layout hideCtaButton={true}>
			<Seo post={pageInfo} />
			<div className={`${styles.top} hero`}>
				{ introBGMobile && <FluidImage image={introBGMobile} passedClass={styles.bgmobile} loading={"eager"} /> }
				{ introBGTablet && <FluidImage image={introBGTablet} passedClass={styles.bgtablet} loading={"eager"} /> }
				{ introBGDesktop && <FluidImage image={introBGDesktop} passedClass={styles.bgdesktop} loading={"eager"} /> }
				{ introBGXL && <FluidImage image={introBGXL} passedClass={styles.bgxl} loading={"eager"} /> }
				{ headerLogo && <Link to="/" className={styles.logolink}><FluidImage image={headerLogo} passedClass={styles.logo} loading={"eager"} /></Link>}
				<WPLink link={{target: '', title: 'Make an Appointment', url: '/schedule-online/'}} passedClass={styles.mobileapptlink} passedIconClass={styles.icon} />


				<div className={styles.left}>
					<h1 className={styles.patientname} dangerouslySetInnerHTML={{__html: title}}></h1>
					{ introHeading && <div className={styles.heading} dangerouslySetInnerHTML={{__html: introHeading}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></div> }
					{ introBlurb && <div className={styles.blurb} dangerouslySetInnerHTML={{__html: introBlurb}} data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease-in"></div> }
					{ introLink && <WPLink link={introLink} icon={rightArrowYellow} passedClass={styles.link} passedIconClass={styles.icon} data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease-in" /> }
				</div>

				<div className={styles.right}>
					<div className={styles.lefttop}>
						<h1 className={styles.patientname} dangerouslySetInnerHTML={{__html: title}}></h1>
					</div>
					<div className={styles.leftbottom}>
						{ blurb && <div className={styles.patientheading} dangerouslySetInnerHTML={{__html: blurb}}></div> }
						{ requestAppointmentLink && <WPLink link={requestAppointmentLink} passedClass={styles.request} /> }
						{ !requestAppointmentLink && appointmentPage && appointmentPage.nodes.length > 0 && <Link to={appointmentPage.nodes[0].link} className={styles.request}>Request appointment</Link> }
					</div>
				</div>
			</div>
			<div className={styles.bottom}>
				
				<div className={styles.left}>
					<div className={styles.patientcontent}>
						<div className={styles.contentleft}>
							{ content && <div className="wpcontent" dangerouslySetInnerHTML={{__html: content}}></div> }
						</div>
					</div>
				</div>

				<div className={styles.right}>
					<div className={styles.insurance}>
						<span className={styles.heading} data-sal-delay="200" data-sal-easing="ease-in">{ insurancesHeading ? insurancesHeading : 'We accept the following insurance.' }</span>
						<div className={styles.insurancebottom} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
							{ insurances && insurances.map( (insurance, index) => {
								const image = insurance.ACFInsurance.logo;
								return image ? <FluidImage key={`insuranceimg-${index}`} image={image} passedClass={styles.insuranceimage} /> : null;
							})}
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export const query = graphql`
	query($id: String!) {
		pageInfo: wpPage(id: { eq: $id }) {
			seo {
				title
				metaDesc
				focuskw
				metaKeywords
				metaRobotsNoindex
				metaRobotsNofollow
				opengraphTitle
				opengraphDescription
				opengraphImage {
					altText
					sourceUrl
					srcSet
				}
				twitterTitle
				twitterDescription
				twitterImage {
					altText
					sourceUrl
					srcSet
				}
				canonical
				cornerstone
				schema {
					articleType
					pageType
					raw
				}
			}
			id
			title
			ACFPageInfo {
				pageInfo {
					left {
						blurb
						insurancesHeading
						requestAppointmentLink {
							title
							url
							target
						}
					}
					right {
						introContent {
							blurb
							heading
							link {
								target
								title
								url
							}
						}
						pageContent
					}
				}
			}
		}
		home: wpPage(isFrontPage: {eq: true}) {
			ACFHomePage {
				hero {
					quickLinks {
						links {
							link {
								target
								title
								url
							}
						}
					}
				}
			}
		}
		options: wp {
			headerSection {
				ACFHeaderOptions {
					mainNavigationSettings {
						headerLogo {
							altText
							localFile {
								childImageSharp {
									fluid(maxWidth: 420){
										...GatsbyImageSharpFluid_withWebp
									}
								}
								publicURL
                				extension
							}
						}
					}
				}
			}
		}
		rightArrowYellowOffsite: file(name: {eq: "arrow_offsite"}) {
			childImageSharp {
			  fixed(width: 10) {
				...GatsbyImageSharpFixed_withWebp
			  }
			}
		}
		rightArrowYellow: file(name: {eq: "arrow"}) {
			childImageSharp {
			  fixed(width: 9) {
				...GatsbyImageSharpFixed_withWebp
			  }
			}
		}
		appointmentPage: allWpPage(filter: {template: {templateName: {eq: "Appointments Page"}}}) {
			nodes {
			  link
			}
		}
		arrowBlue: file(name: {eq: "arrow_blue"}) {
			childImageSharp {
			  fixed(width: 9) {
				...GatsbyImageSharpFixed_withWebp
			  }
			}
		}
		introBGMobile: file(name: {eq: "leap-kids-dental-hero"}) {
			childImageSharp {
			  fluid(maxWidth: 699) {
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		}
		introBGTablet: file(name: {eq: "leap-kids-dental-hero"}) {
			childImageSharp {
			  fluid(maxWidth: 550) {
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		}
		introBGDesktop: file(name: {eq: "leap-kids-dental-hero"}) {
			childImageSharp {
			  fluid(maxWidth: 840) {
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		}
		introBGXL: file(name: {eq: "leap-kids-dental-hero"}) {
			childImageSharp {
			  fluid(maxWidth: 1200) {
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		}
		insurance: allWpInsurance (sort: {fields: title, order: ASC}) {
		    nodes {
				title
		      	ACFInsurance {
		        	logo {
		        		altText
						localFile {
							childImageSharp {
								fluid(maxWidth: 420){
									...GatsbyImageSharpFluid_withWebp
								}
							}
							publicURL
                			extension
						}
		        	}
		      	}
		    }
		}
	}
`;